import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
//import QrReader from '../../components/qrReader/QrReader';
import Html5QrcodePlugin from '../../components/qrReader/Html5QrcodePlugin';
import "./QrStyles.css"
import { getAuthToken } from "../../utils/AuthToken";
import jwt from 'jsonwebtoken';

const QRCodeScanner = ({ history, ...props }) => {
    const { show, handleClose } = props;

    const [startScanner, setStartScanner] = useState(false);
    const [scanData, setScanData] = useState(null);

    const [decodedResults, setDecodedResults] = useState([]);
    const onNewScanResult = (decodedText, decodedResult) => {

        setStartScanner(false);
        if (decodedResult?.result?.text) {
            const decodedToken = jwt.decode(decodedResult?.result.text, { complete: true });
            if(decodedToken?.payload?.data){
                setScanData(JSON.parse(decodedToken?.payload?.data));
            }            
        }
    };


    const handleCloseAction = () => {
        handleClose();
    }

    const handleLongString = (str) => {
        if(str.length > 40) {
            return(
                <div style={{width: '250px', overflow: 'auto'}}> {str} </div>
            )            
        }
        return str;
    }   


    return (
        <>
            <Modal
                show={show}
                onHide={() => {handleCloseAction()}}
                backdrop="static"
                keyboard={false}
                className="qr-code-scanner"
            >
                <>
                    <Modal.Header closeButton className="bookVerify">
                        <Modal.Title>QR Code Scanner</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="py-0 px-0">
                        <div className="container mt-2">
                            <div className="card">
                                <div className="row mt-0">
                                   {
                                    !scanData  &&                                 
                                        <Html5QrcodePlugin
                                            fps={10}
                                            qrbox={600}
                                            disableFlip={false}
                                            qrCodeSuccessCallback={onNewScanResult}
                                            defaultZoomValueIfSupported={4}
                                        />

                                    }
                                    {
                                      scanData ?
                                      <>
                                      <table width={650}>
                                        {
                                            Object.keys(scanData).map((item) => {
                                                return(
                                                    <tr>
                                                        <td width="30%">{item}</td>
                                                        <td width="2%">:</td>
                                                        <td style={{verticalAlign: 'top'}}>
                                                            {handleLongString(scanData[item])}
                                                        </td>
                                                    </tr>
                                                )
                                              })
                                        }
                                      </table>
                                      </>
                                      
                                      : null
                                    }                                    
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </>
            </Modal>
        </>
    );
};

export default QRCodeScanner;
