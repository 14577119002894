/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { BASE_PATH } from '../../app/constants';
import { decodeParamsHeaderMulti, transformData } from '../../utils/UtilityFunctions';
import ShowAlert from '../../components/ShowAlert';
import CM_gif from '../../images/CM_gif.gif';
import './reco_dashboard.scss';
import useIntersectionObserver from './useIntersectionObserver';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton12ColumnGrid from '../../components/Skeleton/Skeleton12ColumnGrid.jsx';
import TallyProgressPopup from '../returnDashboard/TallyProgressPopup.jsx';

// Lazy load the components
const RecoSummeryData = lazy(() => import('./RecoSummeryData'));
const VendorPendingInvoice = lazy(() => import('./VendorPendingInvoice'));
const SupplierDelayInR1 = lazy(() => import('./SupplierDelayInR1'));
const ConflictSummery = lazy(() => import('./ConflictSummery'));
const ToleranceSetting = lazy(() => import('./ToleranceSetting'));
const AmendeInvoiceSummary = lazy(() => import('./AmendeInvoiceSummary'));
const RateTaxSummary = lazy(() => import('./RateTaxSummary'));
const Purchase2B = lazy(() => import('./Purchase2B'));
const TaxableValueTrend = lazy(() => import('./TaxableValueTrend'));
const TaxValueTrend = lazy(() => import('./TaxValueTrend'));
const InvoiceWiseSummery = lazy(() => import('./InvoiceWiseSummery'));
const RateTaxMajorChart = lazy(() => import('./RateTaxMajorChart'));
const SupplierMajorChart = lazy(() => import('./SupplierMajorChart'));
const ModelForVednorDelay = lazy(() => import('./ModelForVednorDelay'));
const ModelForSupplierDelaying = lazy(() => import('./ModelForSupplierDelaying'));
const BooksDataV2 = lazy(() => import('../bookList/BooksDataV2.jsx'));
const ConflictPopupV2 = lazy(() => import('../2AReco/V2/invoiceReco/ConflictPopupV2'));
const SupplierWithMajorDiffBooks2B = lazy(() => import('./SupplierWithMajorDiffBooks2B'));
const Reco2BDataWarning = lazy(() => import('./Reco2BDataWarning'));

const RecoDashboard = () => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const { company_id, month, encodedParams } = params;
    const return_financial_year = parseInt(params?.return_financial_year) > 0 ? params?.return_financial_year : new Date().getFullYear()
    const year = parseInt(params.return_financial_year) > 0 ? params.return_financial_year : parseInt(params.year) > 0 ? params.year : new Date().getFullYear();
    const resolvedYear = year ? (month < 4 ? parseInt(year) - 1 : year) : new Date().getFullYear();
    const [requestedDataFormat, setRequestedDataFormat] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [bookListPopup, setBookListPopup] = useState(false);
    const [showVendorWiseInvoice, setShowVendorWiseInvoice] = useState(false);
    const [showModelForSupplierDelaying, setShowModelForSupplierDelaying] = useState(false);
    const [showConflictPopup, setShowConflictPopup] = useState(false);
    const [showSupplierWithMajorDiff, setShowSupplierWithMajorDiff] = useState(false);
    const [selectedGlobalYears, setSelectedGlobalYears] = useState([]);

    const [vendorRef, isVendorVisible] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    });

    // const [supplierRef, isSupplierVisible] = useIntersectionObserver({
    //     root: null,
    //     rootMargin: '0px',
    //     threshold: 0.1,
    // });

    const mainContainerRef = useRef(null);

    useEffect(() => {
        const { selectedYears, selectedMonths } = decodeParamsHeaderMulti(encodedParams);
        setSelectedGlobalYears(selectedYears);
        const transformedData = transformData(selectedYears, selectedMonths);
        setRequestedDataFormat(transformedData);
        //eslint-disable-next-line
    }, [company_id, encodedParams]);

    useEffect(() => {
        if (mainContainerRef.current) {
            //mainContainerRef.current.focus();
        }
    }, [showVendorWiseInvoice, showModelForSupplierDelaying, showConflictPopup, showSupplierWithMajorDiff]);

    return (
        <>
            <ShowAlert
                error={error}
                success={success}
                onClose={() => { setError(null); setSuccess(null); }}
            />
            <TallyProgressPopup />

            {showConflictPopup && (
                <Suspense fallback={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                        <CircularProgress />
                    </div>
                }>
                    <ConflictPopupV2
                        show={showConflictPopup}
                        closeModal={() => { setShowConflictPopup(false); }}
                        onHide={() => {
                            setShowConflictPopup(false);
                        }}
                        gstrKey="gstr2b"
                        companyId={company_id}
                        filterYear={year}
                        requestedDataFormat={requestedDataFormat}
                        sectionMode={'2A'}
                    />
                </Suspense>
            )}

            {showModelForSupplierDelaying && (
                <Suspense fallback={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                        <CircularProgress />
                    </div>
                }>
                    <ModelForSupplierDelaying
                        company_id={company_id}
                        onClose={() => setShowModelForSupplierDelaying(false)}
                        year={year}
                        month={month}
                        requestedDataFormat={requestedDataFormat}
                    />
                </Suspense>
            )}

            {showVendorWiseInvoice && (
                <Suspense fallback={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                        <CircularProgress />
                    </div>
                }>
                    <ModelForVednorDelay
                        company_id={company_id}
                        onClose={() => setShowVendorWiseInvoice(false)}
                        year={year}
                        month={month}
                        requestedDataFormat={requestedDataFormat}
                    />
                </Suspense>
            )}

            {showSupplierWithMajorDiff && (
                <Suspense fallback={
                    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                    //     <CircularProgress />
                    // </div>
                    <Skeleton12ColumnGrid />
                }>
                    <SupplierWithMajorDiffBooks2B
                        company_id={company_id}
                        onClose={() => setShowSupplierWithMajorDiff(false)}
                        year={year}
                        month={month}
                        requestedDataFormat={requestedDataFormat}
                        return_financial_year={return_financial_year}
                    />
                </Suspense>
            )}

            <div className="container-fluid" ref={mainContainerRef} tabIndex={-1}>
                <div className="row mt-2">
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12">
                        <Link
                            to={`${BASE_PATH}company/dashboard?company_id=${company_id}&year=${resolvedYear}`}
                            className="backtoclrlistofcomp"
                        >
                            <span className="supperwise_reco_by2ab_backbtn">
                                &lt; Back to GSTIN Dashboard
                            </span>
                        </Link>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12">
                        <h4 className="reco_dashboard_maintitlefs">
                            Purchase/Input Supply Dashboard
                        </h4>
                    </div>
                </div>

                <Suspense fallback={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                        <CircularProgress />
                    </div>
                }>
                    <Reco2BDataWarning />
                </Suspense>

                <Suspense fallback={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                        <CircularProgress />
                    </div>
                }>
                    <RecoSummeryData
                        requestedDataFormat={requestedDataFormat}
                        company_id={company_id}
                        return_financial_year={return_financial_year}
                        year={year}
                        selectedGlobalYears={selectedGlobalYears}
                    />
                </Suspense>

                <div className="row mt-2" ref={vendorRef}>
                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <VendorPendingInvoice
                            requestedDataFormat={requestedDataFormat}
                            setShowVendorWiseInvoice={setShowVendorWiseInvoice}
                            company_id={company_id}
                            isVisible={isVendorVisible}
                        />
                    </Suspense>

                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <SupplierDelayInR1
                            requestedDataFormat={requestedDataFormat}
                            setShowModelForSupplierDelaying={setShowModelForSupplierDelaying}
                            company_id={company_id}
                        // isVisible={isSupplierVisible}
                        />
                    </Suspense>
                </div>

                <Suspense fallback={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                        <CircularProgress />
                    </div>
                }>
                    <ConflictSummery
                        requestedDataFormat={requestedDataFormat}
                        company_id={company_id}
                        return_financial_year={return_financial_year}
                        encodedParams={encodedParams}
                    />
                </Suspense>

                <div className="row mt-2">
                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <RateTaxMajorChart
                            params={params}
                            requestedDataFormat={requestedDataFormat}
                            decodeParamsHeaderMulti={decodeParamsHeaderMulti}
                        />
                    </Suspense>

                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <SupplierMajorChart
                            params={params}
                            requestedDataFormat={requestedDataFormat}
                            decodeParamsHeaderMulti={decodeParamsHeaderMulti}
                            setShowSupplierWithMajorDiff={setShowSupplierWithMajorDiff}
                        />
                    </Suspense>
                </div>

                <Suspense fallback={
                    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                    //     <CircularProgress />
                    // </div>
                    <Skeleton12ColumnGrid />
                }>
                    <InvoiceWiseSummery
                        requestedDataFormat={requestedDataFormat}
                        company_id={company_id}
                        return_financial_year={return_financial_year}
                        encodedParams={encodedParams}
                    />
                </Suspense>

                <div className="row">
                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <TaxableValueTrend
                            requestedDataFormat={requestedDataFormat}
                            company_id={company_id}
                            finacialYear={year}
                        />
                    </Suspense>

                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <TaxValueTrend
                            requestedDataFormat={requestedDataFormat}
                            company_id={company_id}
                            finacialYear={year}
                        />
                    </Suspense>
                </div>

                <div className="row mb-2">
                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <Purchase2B
                            params={params}
                            requestedDataFormat={requestedDataFormat}
                            decodeParamsHeaderMulti={decodeParamsHeaderMulti}
                        />
                    </Suspense>

                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <RateTaxSummary
                            params={params}
                            requestedDataFormat={requestedDataFormat}
                        />
                    </Suspense>
                </div>

                <div className="row mb-2">
                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <ToleranceSetting company_id={company_id} />
                    </Suspense>

                    <Suspense fallback={
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                            <CircularProgress />
                        </div>
                    }>
                        <AmendeInvoiceSummary
                            company_id={company_id}
                            requestedDataFormat={requestedDataFormat}
                        />
                    </Suspense>
                </div>
            </div>

            <div className="gstr1leftsidepopupbox import_button">
                <div className="AnnouncementsIcon planexpiredalert" data-toggle="modal" data-target="#updatebooks2adatam">
                    <div className="Announcements">
                        <div className="Announcements-wrapper has-unseen-items Dropdown">
                            <div className="Dropdown-toggle Button">
                                <div className="Announcements-bubble">&nbsp;</div>
                                <FontAwesomeIcon icon={faBullhorn} style={{ color: '#fff', fontSize: '11px', transform: 'rotate(-38deg)' }} />
                            </div>
                        </div>
                    </div>
                </div>

                {/*eslint-disable-next-line*/}
                <a href="#" id='htm_recodashboard-import_books_data' onClick={() => setBookListPopup(true)} className="mb-0 btn planexpiredalert" type="button">
                    Import Books Data
                    <span className="uploaddataicon">
                        <img className="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAyCAYAAABGQBuoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1zbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1zbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBNTRGQzU4NjAzQjYxMUVCODU1Mzk3QTdFOEJGMzFBRCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBNTRGQzU4NzAzQjYxMUVCODU1Mzk3QTdFOEJGMzFBRCI+IDx4bXBNTTpEZXJpdmVkRnJvbCBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkE1NEZDNTg0MDNCNjExRUI4NTUzOTdBN0U4QkYzMUFEIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkE1NEZDNTg1MDNCNjExRUI4NTUzOTdBN0U4QkYzMUFEIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3g6eG1wbWV0YT4gPz4ByVheAAAAdElEQVR42uySMQ0AIAxEwArfPOJ7K5B3CkOjCSEru3M5vZtAC8HYKZqYqk3BASGSdhBYDoYiStWCcCAAAEnJQqTJUHmyJQkSVoqwiAA5qJUUkJAHmiFEUkJAJiqUkEkAHWKlFpKUAHCh5QyJKVoqlBSQEAlIqUUkJAHWqlJJQVAABJZSoqlBSQEBFSzGgC1n3Pxtt+AAAAAElFTkSuQmCC" alt="" />
                    </span>
                </a>
            </div>

            <div className="gifimgmainbox" onClick={() => setShowConflictPopup(true)} id='htm_recodashboard-conflicts_popup_button'>
                <span className="bottomleftsidegifimgbox">
                    <img src={CM_gif} width="105" alt='' />
                </span>
            </div>

            {bookListPopup && (
                <Suspense fallback={
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '220px' }}>
                        <CircularProgress />
                    </div>
                }>
                    
                    <BooksDataV2
                        company_id={params.company_id}
                        show={bookListPopup}
                        handleClose={() => {
                            setBookListPopup(false);
                        }}
                        filterYear={parseInt(year) > 0 ? year : new Date().getFullYear()}
                        handleProcced={(msg, isRefresh = false) => {
                            setBookListPopup(false);
                            if (msg) setSuccess(msg);
                            if (isRefresh) { window.location.reload(); }
                        }}
                        setBookListPopup={setBookListPopup}
                    />
                </Suspense>
            )}
        </>
    );
}

export default RecoDashboard;
