/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../multiLinking.scss';
import GSTR2BTable from './GSTR2BTable';
import Books2BTable from './Books2BTable';
import { linkB2b, linkImp, itsClaim } from '../../../api/MultiLinkingApi';
import RangeSelector from '../common/RangeSelector';
import CheckboxGroup from '../common/CheckboxGroup';
import AutoCompleteInput from '../common/AutoCompleteInput';
import ShowAlert from '../../../components/ShowAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import ConfirmPopup from '../../../components/ConfirmPopup';

const LinkingBy2B = (props) => {
    const {
        selectedSeller,
        handleSellerSelect,
        company_id,
        requestedDataFormat,
        fullResponse,
        mobile_number,
        gst_number,
        selectedMode,
        setSelectedMode,
        gstrKey,
    } = props;

    const [radioSelected, setRadioSelected] = useState(selectedMode);
    const [checkedDateBooks, setCheckedDateBooks] = useState(null);
    const [checkedDateGstr, setCheckedDateGstr] = useState(null);
    const [checkedItemsBooks, setCheckedItemsBooks] = useState({});
    const [checkedItemsGSTR, setCheckedItemsGSTR] = useState({});
    const [renderBooks, setRenderBooks] = useState(false);
    const [renderPortal, setRenderPortal] = useState(false);
    const [firstSelection, setFirstSelection] = useState(null);
    const [refDetails, setRefDetails] = useState([]);
    const [showPopup, setShowPopup] = useState("");
    const [AllDocs, setAllDocs] = useState({
        books: false,
        gstr2b: false,
    });
    const [booksRange, setBooksRange] = useState({
        range: 'On or Before / After',
        value: '15',
        unit: 'Days',
    });

    const [gstr2bRange, setGstr2bRange] = useState({
        range: 'On or Before / After',
        value: '15',
        unit: 'Days',
    });

    const [booksCheckboxes, setBooksCheckboxes] = useState({
        I: true,
        C: true,
        D: true,
    });

    const [gstr2bCheckboxes, setGstr2bCheckboxes] = useState({
        I: true,
        C: true,
        D: true,
    });

    const [fromDateBooks, setFromDateBooks] = useState('');
    const [toDateBooks, setToDateBooks] = useState('');
    const [fromDateGstr2b, setFromDateGstr2b] = useState('');
    const [toDateGstr2b, setToDateGstr2b] = useState('');

    const [alertSuccess, setAlertSuccess] = useState(null);
    const [alertError, setAlertError] = useState(null);

    // Arrays to keep track of selected dates
    const [booksSelectedDates, setBooksSelectedDates] = useState([]);
    const [gstrSelectedDates, setGstrSelectedDates] = useState([]);

    //Setting mode in local Storage
    useEffect(() => {
        const key = `selectedOption_${mobile_number}_${gst_number}`;
        const getKey = localStorage.getItem(key);
        setSelectedMode(getKey);
    }, [])

    // Effect to clear checkboxes when the mode changes
    useEffect(() => {
        setCheckedItemsBooks({});
        setCheckedItemsGSTR({});
        setBooksSelectedDates([]);
        setGstrSelectedDates([]);
        setFirstSelection(null); // Reset first selection on mode change
    }, [requestedDataFormat, AllDocs, selectedMode]);


    const handleBooksRangeChange = (key, value) => {
        setBooksRange((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleGstr2bRangeChange = (key, value) => {
        setGstr2bRange((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleBooksCheckboxChange = (key) => {
        setBooksCheckboxes((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const handleGstr2bCheckboxChange = (key) => {
        setGstr2bCheckboxes((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    //Setting mode in local Storage
    const handleRadioChange = (e) => {
        const newMode = e.target.value;
        setRadioSelected(newMode);
        setSelectedMode(newMode);
        const key = `selectedOption_${mobile_number}_${gst_number}`;
        localStorage.setItem(key, newMode);
    };

    useEffect(() => {
        setRadioSelected(selectedMode);
    }, [selectedMode]);

    const calculateDate = (date, offset, unit, operation) => {
        const newDate = new Date(date);
        if (unit.toLowerCase() === 'days') {
            newDate.setDate(newDate.getDate() + (operation === 'add' ? offset : -offset));
        } else if (unit.toLowerCase() === 'weeks') {
            newDate.setDate(newDate.getDate() + (operation === 'add' ? offset * 7 : -offset * 7));
        } else if (unit.toLowerCase() === 'months') {
            newDate.setMonth(newDate.getMonth() + (operation === 'add' ? offset : -offset));
        }
        return newDate;
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Effect for Books range based on GSTR2B date
    useEffect(() => {
        if (checkedDateGstr) {
            const baseDate = new Date(checkedDateGstr);
            const rangeValue = parseInt(booksRange.value, 10) || 0;

            let fromDate = null;
            let toDate = null;

            switch (booksRange.range) {
                case 'On or Before / After':
                    fromDate = calculateDate(baseDate, rangeValue, booksRange.unit, 'subtract');
                    toDate = calculateDate(baseDate, rangeValue, booksRange.unit, 'add');
                    break;
                case 'On or Before':
                    fromDate = calculateDate(baseDate, rangeValue, booksRange.unit, 'subtract');
                    toDate = baseDate;
                    break;
                case 'On or After':
                    fromDate = baseDate;
                    toDate = calculateDate(baseDate, rangeValue, booksRange.unit, 'add');
                    break;
                default:
                    fromDate = baseDate;
                    toDate = baseDate;
            }

            setFromDateBooks(fromDate ? formatDate(fromDate) : '');
            setToDateBooks(toDate ? formatDate(toDate) : '');
        } else {
            setFromDateBooks('');
            setToDateBooks('');
        }
    }, [checkedDateGstr, booksRange]);

    // Effect for GSTR2B range based on Books date
    useEffect(() => {
        if (checkedDateBooks) {
            const baseDate = new Date(checkedDateBooks);
            const rangeValue = parseInt(gstr2bRange.value, 10) || 0;

            let fromDate = null;
            let toDate = null;

            switch (gstr2bRange.range) {
                case 'On or Before / After':
                    fromDate = calculateDate(baseDate, rangeValue, gstr2bRange.unit, 'subtract');
                    toDate = calculateDate(baseDate, rangeValue, gstr2bRange.unit, 'add');
                    break;
                case 'On or Before':
                    fromDate = calculateDate(baseDate, rangeValue, gstr2bRange.unit, 'subtract');
                    toDate = baseDate;
                    break;
                case 'On or After':
                    fromDate = baseDate;
                    toDate = calculateDate(baseDate, rangeValue, gstr2bRange.unit, 'add');
                    break;
                default:
                    fromDate = baseDate;
                    toDate = baseDate;
            }

            setFromDateGstr2b(fromDate ? formatDate(fromDate) : '');
            setToDateGstr2b(toDate ? formatDate(toDate) : '');
        } else {
            setFromDateGstr2b('');
            setToDateGstr2b('');
        }
    }, [checkedDateBooks, gstr2bRange]);


    const toLink = async () => {
        const data = {
            company_id: company_id,
            ref_details: refDetails,
        };

        try {
            const result = await itsClaim(data)
            if (result.data.gstr3b) {
                setShowPopup('The selected invoices might have been claimed in GSTR3B. Linking may result in reclaiming the ITC already claimed for particular invoices. Do you want to continue?')
            } else if (result.data.itc_claim_status) {
                setShowPopup('Linking invoices might impact your ITC claimed status for GSTR3B Filing')
            } else {
                handleLinkClick()
            }
        } catch (err) {
            setAlertError(err.message);
        }
    }



    const handleLinkClick = async () => {
        const booksData = Object.entries(checkedItemsBooks).map(([id, item]) => ({
            books_id: id,
            doc_type: item.doc_type,
        }));

        const portalData = Object.entries(checkedItemsGSTR).map(([id, item]) => ({
            portal_id: id,
            doc_type: item.doc_type,
        }));

        const requestData = {
            company_id: company_id,
            match_remarks: '',
            books_data: booksData,
            portal_data: portalData,
        };

        const clearCheckedItems = () => {
            setCheckedItemsBooks({});
            setCheckedItemsGSTR({});
        };

        try {
            let result;
            if (selectedMode === 'IMPG' || fullResponse.doc_type === 'IMPGSEZ') {
                result = await linkImp(requestData);
            } else {
                result = await linkB2b(requestData);
            }


            if (result.status === 1) {
                setAlertSuccess(result.message);
                setAlertError(null);
                setRenderBooks(true);
                setRenderPortal(true);
                clearCheckedItems(); // Clear the checked items on successful linking

                // Fetch data again after linking
                setRenderBooks(false);
                setRenderPortal(false);
                setRenderBooks(true);
                setRenderPortal(true);
                setTimeout(()=>{
                    window.location.reload();
                },3000)
                
            } else {
                setAlertError(result.message);
                setAlertSuccess(null);
                setRenderBooks(false);
                setRenderPortal(false);
            }
        } catch (error) {
            setAlertError(error.message);
            setAlertSuccess(null);
            setRenderBooks(false);
            setRenderPortal(false);
        }
    };

    const handleAllDocsToggle = (type) => {
        setAllDocs((prevState) => ({
            ...prevState,
            [type]: !prevState[type],
        }));
    };

    const handleGstrTableSelection = (selectedItems) => {
        setCheckedItemsGSTR(selectedItems);

        if (!firstSelection) {
            setFirstSelection('gstr');
        }

        const newSelectedDates = Object.keys(selectedItems).reduce((acc, id) => {
            acc[id] = selectedItems[id].doc_date;
            return acc;
        }, { ...gstrSelectedDates });

        Object.keys(newSelectedDates).forEach((id) => {
            if (!selectedItems[id]) {
                delete newSelectedDates[id];
            }
        });

        setGstrSelectedDates(newSelectedDates);

        if (Object.keys(selectedItems).length === 0 && firstSelection === 'gstr') {
            setCheckedItemsBooks({});
            setFirstSelection(null);
        }
    };

    const handleBooksTableSelection = (selectedItems) => {
        setCheckedItemsBooks(selectedItems);

        if (!firstSelection) {
            setFirstSelection('books');
        }

        const newSelectedDates = Object.keys(selectedItems).reduce((acc, id) => {
            acc[id] = selectedItems[id].doc_date;
            return acc;
        }, { ...booksSelectedDates });

        Object.keys(newSelectedDates).forEach((id) => {
            if (!selectedItems[id]) {
                delete newSelectedDates[id];
            }
        });

        setBooksSelectedDates(newSelectedDates);

        if (Object.keys(selectedItems).length === 0 && firstSelection === 'books') {
            setCheckedItemsGSTR({});
            setFirstSelection(null);
        }
    };

    const isLinkDisabled = Object.keys(checkedItemsBooks).length === 0 || Object.keys(checkedItemsGSTR).length === 0;

    // Determine the current sorting date
    const getCurrentSortingDate = () => {
        if (firstSelection === 'books' && Object.keys(booksSelectedDates).length > 0) {
            const bookKeys = Object.keys(booksSelectedDates);
            const lastBookKey = bookKeys[bookKeys.length - 1];
            return booksSelectedDates[lastBookKey];
        } else if (firstSelection === 'gstr' && Object.keys(gstrSelectedDates).length > 0) {
            const gstrKeys = Object.keys(gstrSelectedDates);
            const lastGstrKey = gstrKeys[gstrKeys.length - 1];
            return gstrSelectedDates[lastGstrKey];
        }
        return null;
    };


    useEffect(() => {
        const currentSortingDate = getCurrentSortingDate();
        if (firstSelection === 'books') {
            setCheckedDateBooks(currentSortingDate);
        } else if (firstSelection === 'gstr') {
            setCheckedDateGstr(currentSortingDate);
        }
    }, [booksSelectedDates, gstrSelectedDates, firstSelection]);

    return (
        <div className="tab-pane fade active show" id="supperwise_reco_by2ab_2b" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="row">
                <div className="col-md-12">
                    <div className="card pt-1 mt-3 px-2 pb-2">
                        <div className="row">
                            
                                <div className="col-12 col-xl-4 col-lg-4 col-md-5">
                                    {firstSelection === 'gstr' && Object.keys(checkedItemsGSTR).length > 0 && (
                                        <RangeSelector elementId={`htm_multilinking_2b_books`} range={booksRange} handleRangeChange={handleBooksRangeChange} requestedDataFormat={requestedDataFormat} />
                                    )}
                                </div>
                                <div className="col-12 col-xl-4 col-lg-3 col-md-7 text-center">
                                    <div className="form-check-inline manytomany_radiobox pl-4">
                                        <label className="form-check-label" id='htm_multilinking_2b-type_b2b_imagesez_lbl'>
                                            <input
                                                id='htm_multilinking_2b-type_b2b_imagesez_radio'
                                                type="radio"
                                                className="form-check-input hideSellerSelectionPopup"
                                                name="mode"
                                                value="B2B/IMPGSEZ"
                                                checked={radioSelected === 'B2B/IMPGSEZ'}
                                                onChange={handleRadioChange}
                                            />
                                            B2B / IMPGSEZ
                                        </label>
                                    </div>
                                    <div className="form-check-inline manytomany_radiobox pl-4">
                                        <label className="form-check-label" id='htm_multilinking_2b-type_image_lbl'>
                                            <input
                                                id='htm_multilinking_2b-type_image_radio'
                                                type="radio"
                                                className="form-check-input"
                                                name="mode"
                                                value="IMPG"
                                                checked={radioSelected === 'IMPG'}
                                                onChange={handleRadioChange}
                                            />
                                            IMPG
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-4 col-lg-5 col-md-12 text-right">
                                    {firstSelection === 'books' && Object.keys(checkedItemsBooks).length > 0 && (
                                        <RangeSelector elementId={`htm_multilinking_2b_impg`} range={gstr2bRange} handleRangeChange={handleGstr2bRangeChange} requestedDataFormat={requestedDataFormat} />
                                    )}
                                    <span
                                        id='htm_multilinking_2b-linking_button'
                                        className={`btn btn-default manytomany_rangedrop_linkbtn mr-2 ${isLinkDisabled ? 'disabled' : ''}`}
                                        onClick={isLinkDisabled ? null : toLink}
                                    >
                                        Link
                                    </span>
                                </div>
                           
                            <div className="col-md-12 text-center">
                                <div className="autocomplete-container ratereco_multilink_inputboxwidth">
                                    {radioSelected !== 'IMPG' && (
                                        <AutoCompleteInput
                                            company_id={company_id}
                                            requestedDataFormat={requestedDataFormat}
                                            gst_number={gst_number}
                                            mobile_number={mobile_number}
                                            handleSellerSelect={handleSellerSelect}
                                            mode={selectedMode}
                                            width={"300px"}
                                            elementId={`htm_multilinking-supplier_selection_input_2b`}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2 mb-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12 col-xl-5 col-lg-6 col-md-8 mt-2">
                                        {selectedMode !== 'IMPG' ? (
                                            fullResponse && fullResponse.doc_type === 'IMPGSEZ' ? (
                                                "IMPGSEZ"
                                            ) : <div className="manytomany_rangedrop_b2bcheckboxinline">
                                                <CheckboxGroup elementId={`htm_multilinking_2b_books`} checkboxes={booksCheckboxes} handleCheckboxChange={handleBooksCheckboxChange} />
                                            </div>
                                        ) : 'IMPG'}
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-3 col-md-4 mt-1">
                                        <div className="manytomany_rangedrop_b2bcheckboxinline">
                                            <h4 className="manytomany_rangedrop_books_fs pl-5" style={{ display: 'inline-block' }}>
                                                Books
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-3 col-lg-3 col-md-12 text-right">
                                        <div className="manytomany_togglebtn pr-2">
                                            <span className="manytomany_togglebtn_alldoc_fs">All Docs</span>
                                            <label className="switch manytomany_toggleswit" id='htm_multilinking-supplier_selection_2b_books_all_docs'>
                                                <input type="checkbox" checked={AllDocs.books} onChange={() => handleAllDocsToggle('books')} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12 col-xl-5 col-lg-6 col-md-8 mt-2">
                                        {selectedMode !== 'IMPG' ? (
                                            fullResponse && fullResponse.doc_type === 'IMPGSEZ' ? (
                                                "IMPGSEZ"
                                            ) : <div className="manytomany_rangedrop_b2bcheckboxinline">
                                                <CheckboxGroup elementId={`htm_multilinking_2b_gstr2a`} checkboxes={gstr2bCheckboxes} handleCheckboxChange={handleGstr2bCheckboxChange} />
                                            </div>
                                        ) : 'IMPG'}
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-3 col-md-4 mt-1">
                                        <div className="manytomany_rangedrop_b2bcheckboxinline">
                                            <h4 className="manytomany_rangedrop_books_fs" style={{ display: 'inline-block' }}>
                                                GSTR2B
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-3 col-lg-3 col-md-12 text-right">
                                        <div className="manytomany_togglebtn pr-2">
                                            <span className="manytomany_togglebtn_alldoc_fs">All Docs</span>
                                            <label className="switch manytomany_toggleswit" id='htm_multilinking-supplier_selection_2b_gstr2a_all_docs'>
                                                <input type="checkbox" checked={AllDocs.gstr2b} onChange={() => handleAllDocsToggle('gstr2b')} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showPopup.length > 0 &&
                            <ConfirmPopup
                                show={showPopup.length > 0}
                                handleClose={() => {
                                    setShowPopup("")
                                    window.location.reload();
                                }}
                                handleProceed={() => {
                                    setShowPopup("")
                                    handleLinkClick();
                                }}
                                confirmText={showPopup}
                                confirmButtonText={"Yes"}
                            />
                        }
                        <div className="row">
                            <Books2BTable
                                company_id={company_id}
                                requestedDataFormat={requestedDataFormat}
                                fullResponse={fullResponse}
                                selectedSeller={selectedSeller}
                                booksCheckboxes={booksCheckboxes}
                                booksRange={booksRange}
                                selectedMode={selectedMode}
                                fromDateBooks={fromDateBooks}
                                toDateBooks={toDateBooks}
                                setCheckedItemsBooks={handleBooksTableSelection}
                                checkedItemsBooks={checkedItemsBooks}
                                renderBooks={renderBooks}
                                AllDocsBooks={AllDocs.books}
                                setCheckedDateBooks={setCheckedDateBooks}
                                firstSelection={firstSelection}
                                setRefDetails={setRefDetails}
                                refDetails={refDetails}
                                AllDocsGSTR2B={AllDocs.gstr2b}
                            />
                            <GSTR2BTable
                                company_id={company_id}
                                requestedDataFormat={requestedDataFormat}
                                fullResponse={fullResponse}
                                selectedSeller={selectedSeller}
                                gstr2bCheckboxes={gstr2bCheckboxes}
                                gstr2bRange={gstr2bRange}
                                selectedMode={selectedMode}
                                fromDateGstr2b={fromDateGstr2b}
                                toDateGstr2b={toDateGstr2b}
                                checkedItemsGSTR={checkedItemsGSTR}
                                setCheckedItemsGSTR={handleGstrTableSelection}
                                renderPortal={renderPortal}
                                AllDocsGSTR2B={AllDocs.gstr2b}
                                setCheckedDateGstr={setCheckedDateGstr}
                                firstSelection={firstSelection}
                                setRefDetails={setRefDetails}
                                refDetails={refDetails}
                                gstrKey={gstrKey}
                                AllDocsBooks={AllDocs.books}
                            />
                            <div className="col-md-12 text-left">
                                <span className="lablecheckbox ml-2">
                                    <span className="checkboxclr_linkdocs_blueclr pr-1">
                                        <FontAwesomeIcon icon={faSquare} />
                                    </span>
                                    <span className="transwise_linkdocs">Unlinked Docs</span>
                                </span>
                                <span className="lablecheckbox pl-3">
                                    <span className="checkboxclr_linkdocs_redclr pr-1">
                                        <FontAwesomeIcon icon={faSquare} />
                                    </span>
                                    <span className="transwise_linkdocs">Linked Docs</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ShowAlert success={alertSuccess} error={alertError} onClose={() => { setAlertSuccess(null); setAlertError(null); }} />
        </div>
    );
};

export default LinkingBy2B;